import React, { useEffect, useState } from "react";
import { Grid, Typography, Divider, Box } from "@mui/material";
import { HELP_PAGINATION_ITEM_LIMIT } from "../../redux/constants";
import AxiosInstance from "../../AxiosInstance";
import SummaryCard from "./SummaryCard";

const Summary = ({ summaries, updateSummaries }) => {
  return (
    <Grid container item sx={{ justifyContent: "flex-end" }}>
      <Grid container item sx={{ justifyContent: "flex-end" }}>
        <Typography variant="h6">
          {summaries.length > 0 ? "Summary" : ""}
        </Typography>
      </Grid>
      <Grid container item spacing={2}>
        {summaries.map((summary) => {
          return (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <SummaryCard
                summary={summary}
                updateSummaries={updateSummaries}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default Summary;
