import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Link } from "react-router-dom";
import { TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import AxiosInstance from "../../AxiosInstance";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { insert_user_books } from "../../redux/action";
import { HELP_PAGINATION_ITEM_LIMIT } from "../../redux/constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditSummaryDialog({ summary, updateSummaries }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const EditSummarySchema = Yup.object().shape({
    title: Yup.string().required("This field is required!"),
    fxn: Yup.string().required("This field is required!"),
  });

  const EditSummaryForm = useFormik({
    initialValues: {
      title: summary.title,
      fxn: summary.structure.fxns,
      // "structure.fxns": "",
    },
    onSubmit: (values, { setSubmitting }) => {
      EditSummaryForm.setFieldValue(
        "structure.fxns",
        EditSummaryForm.values.fxn.split(",")
      );
      AxiosInstance.patch(`/expensetracker/summary/${summary.id}/`, values)
        .then((resp) => {
          toast.success("Summary edited successfully!");
          setOpen(false);
          EditSummaryForm.resetForm();
          setSubmitting(false);
          updateSummaries();
        })
        .catch((err) => {
          toast.error("Error updating summary!");
          setSubmitting(false);
        });
    },
    validationSchema: EditSummarySchema,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen} disableElevation fullWidth>
        Edit
      </Button>
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Edit Summary"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              focused
              autoFocus
              name="title"
              id="title"
              label="Title"
              onChange={EditSummaryForm.handleChange}
              value={EditSummaryForm.values.title}
            />
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              focused
              autoFocus
              name="fxn"
              id="fxn"
              label="Function"
              onChange={(e) => {
                EditSummaryForm.setFieldValue("fxn", e.target.value);
                EditSummaryForm.setFieldValue(
                  "structure.fxns",
                  e.target.value.split(",")
                );
              }}
              value={EditSummaryForm.values.fxn}
            />
            <Typography>Reference For Summary Formula:</Typography>
            <Typography>
              "SUM" "DIFF" "PROD" "DIV" "ADIV" "REM" "ABS" "ABOOK"
            </Typography>
            <Typography>`Example: SUM(ABOOK(1)),SUM(ABOOK(2))`</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              AxiosInstance.get(
                `/expensetracker/summary/${summary.id}/refresh/`
              )
                .then((resp) => {
                  updateSummaries();
                  setOpen(false);
                  toast.success("Summary refreshed successfully!");
                })
                .catch((err) => {
                  toast.error("Error updating summary!");
                });
            }}
          >
            Refresh
          </Button>
          <Button color="error" onClick={handleClose}>
            Close
          </Button>
          <Button
            color="black"
            onClick={() => {
              AxiosInstance.delete(`/expensetracker/summary/${summary.id}/`)
                .then((resp) => {
                  toast.success("Summary deleted successfully!");
                  setOpen(false);
                  updateSummaries();
                })
                .catch((err) => {
                  toast.error(Object.values(err.response.data)[0][0], {
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
                });
            }}
          >
            Delete
          </Button>
          <Button
            color="primary"
            onClick={EditSummaryForm.handleSubmit}
            disabled={EditSummaryForm.isSubmitting || !EditSummaryForm.isValid}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
