import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Link } from "react-router-dom";
import { TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import AxiosInstance from "../../AxiosInstance";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { insert_user_books } from "../../redux/action";
import { HELP_PAGINATION_ITEM_LIMIT } from "../../redux/constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateNewSummaryDialog({ updateSummaries }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const NewSummarySchema = Yup.object().shape({
    title: Yup.string().required("This field is required!"),
    fxn: Yup.string().required("This field is required!"),
  });

  const CreateNewSummaryForm = useFormik({
    initialValues: {
      title: "",
      fxn: "",
      // "structure.fxns": "",
    },
    onSubmit: (values, { setSubmitting }) => {
      AxiosInstance.post("/expensetracker/summary/", values)
        .then((resp) => {
          toast.success("Summary created successfully!");
          setOpen(false);
          CreateNewSummaryForm.resetForm();
          setSubmitting(false);
          updateSummaries();
        })
        .catch((err) => {
          toast.error("Error creating summary!");
          setSubmitting(false);
        });
    },
    validationSchema: NewSummarySchema,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        sx={{ my: 3 }}
        variant="outlined"
        disableElevation
        component={Link}
        fullWidth
      >
        + Create Summary
      </Button>
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Create New Summary"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              focused
              autoFocus
              name="title"
              id="title"
              label="Title"
              onChange={CreateNewSummaryForm.handleChange}
              value={CreateNewSummaryForm.values.title}
            />
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              focused
              autoFocus
              name="fxn"
              id="fxn"
              label="Function"
              onChange={(e) => {
                CreateNewSummaryForm.setFieldValue("fxn", e.target.value);
                CreateNewSummaryForm.setFieldValue(
                  "structure.fxns",
                  e.target.value.split(",")
                );
              }}
              value={CreateNewSummaryForm.values.fxn}
            />
            <Typography>Reference For Summary Formula:</Typography>
            <Typography>
              "SUM" "DIFF" "PROD" "DIV" "ADIV" "REM" "ABS" "ABOOK"
            </Typography>
            <Typography>`Example: SUM(ABOOK(1)),SUM(ABOOK(2))`</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={CreateNewSummaryForm.handleSubmit}
            disabled={
              CreateNewSummaryForm.isSubmitting || !CreateNewSummaryForm.isValid
            }
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
