import Card from "@mui/material/Card";
import { Button, Box, CardActions, CardHeader } from "@mui/material";

import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import EditSummaryDialog from "./EditSummary";

export default function SummaryCard({ summary, updateSummaries }) {
  return (
    <Card
      variant={"text"}
      disableRipple
      sx={{
        padding: 0,
        color: "blue",
        minHeight: "150px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        height: "100%",
        backgroundSize: "250%",
        transition: "0.6s",
        borderWidth: "10px",
        border: "10px solid transparent",
        borderImage:
          "linear-gradient(45deg, rgba(46,60,232,1) 0%, rgba(71,140,232,1) 39%, rgba(95,225,255,1) 100%) ",
        borderImageSlice: 1,
        WebkitMask:
          "linear-gradient(45deg, rgba(46,60,232,1) 0%, rgba(71,140,232,1) 39%, rgba(95,225,255,1) 100%) ",
      }}
    >
      <CardActions
        sx={{
          width: "100%",
        }}
        disableSpacing
      >
        <Grid
          container
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Grid item>
            <CardHeader
              title={<Typography variant="h6">{summary.title}</Typography>}
            />
          </Grid>
          <Grid item container sx={{ justifyContent: "space-between" }}>
            <Grid item>
              <Typography variant="body" sx={{ px: 1 }}>
                Rs. {summary.summarized_val}/-
              </Typography>
            </Grid>
            <Grid item>
              <EditSummaryDialog
                summary={summary}
                updateSummaries={updateSummaries}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
